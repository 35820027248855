import React, { FC, memo, useEffect } from 'react';
import { sentryHub } from '@app/shared/utils/sentry';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import type { SentryProviderProps } from './SentryProvider.type';

export const SentryProvider: FC<SentryProviderProps> = memo(({ children }) => {
  const { userId, environment, country } = usePublicEnv();

  useEffect(() => {
    try {
      sentryHub.init({ environment });
      sentryHub.setUser({ id: userId });
      sentryHub.setTag('country', country.shortName);
      sentryHub.setTag('templateType', TEMPLATE_TYPE);
      sentryHub.setTag('templateVersion', TEMPLATE_VERSION);
    } catch (error) {
      console.error('Error initializing sentry hub', (error as Error).message);
    }
  }, [country?.shortName, environment, userId]);

  return children;
});
