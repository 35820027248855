import React, { FC } from 'react';
import type { RouterAppProps } from './RouterApp.type';
import { Switch, Route } from 'react-router-dom';
import { Routes } from '@app/shared/config/routes';
import { getItem } from '@app/shared/utils/storage';
import { Constants } from '@app/shared/config/constants';
import { NotFound } from '@app/shared/components/NotFound';
import loadable from '@loadable/component';
import BrandLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const LoadableNotes = loadable(() => import('@app/notes/main/Notes'), {
  resolveComponent: (components) => components.Notes,
  fallback: <BrandLoader position="center" />,
});

const LoadableChangeAddress = loadable(() => import('@app/changeAddress/main/ChangeAddress'), {
  resolveComponent: (components) => components.ChangeAddress,
  fallback: <BrandLoader position="center" />,
});

const LoadableDeliveryType = loadable(() => import('@app/deliveryType/main/DeliveryType'), {
  resolveComponent: (components) => components.DeliveryType,
  fallback: <BrandLoader position="center" />,
});

const LoadableDeliveryPhone = loadable(() => import('@app/deliveryPhone/main/DeliveryPhone'), {
  resolveComponent: (components) => components.DeliveryPhone,
  fallback: <BrandLoader position="center" />,
});

export const RouterApp: FC<RouterAppProps> = ({ children }) => {
  return (
    <Switch>
      <Route
        path={Routes.NOTES}
        render={(props) => {
          const query = new URLSearchParams(props?.location?.search);
          return (
            <LoadableNotes
              isParcel={false}
              flow={query.get('flow') as string}
              mock={getItem(Constants.MOCK_FLAG, false)}
              resourceId={props?.match?.params?.resourceId}
              resourceType={props?.match?.params?.resourceType}
            />
          );
        }}
      ></Route>
      <Route
        path={Routes.CHANGE_ADDRESS}
        render={(props) => {
          const query = new URLSearchParams(props?.location?.search);
          return (
            <LoadableChangeAddress
              isParcel={false}
              flow={query.get('flow') as string}
              mock={getItem(Constants.MOCK_FLAG, false)}
              resourceId={props?.match?.params?.resourceId}
              resourceType={props?.match?.params?.resourceType}
            />
          );
        }}
      />
      <Route
        path={Routes.DELIVERY_TYPE}
        render={(props) => {
          const query = new URLSearchParams(props?.location?.search);
          return (
            <LoadableDeliveryType
              isParcel={false}
              flow={query.get('flow') as string}
              mock={getItem(Constants.MOCK_FLAG, false)}
              resourceId={props?.match?.params?.resourceId}
              resourceType={props?.match?.params?.resourceType}
            />
          );
        }}
      />
      <Route
        path={Routes.DELIVERY_PHONE}
        render={(props) => {
          const query = new URLSearchParams(props?.location?.search);
          return (
            <LoadableDeliveryPhone
              isParcel={false}
              flow={query.get('flow') as string}
              mock={getItem(Constants.MOCK_FLAG, false)}
              resourceId={props?.match?.params?.resourceId}
              resourceType={props?.match?.params?.resourceType}
            />
          );
        }}
      />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};
