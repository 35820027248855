import React, { FC, memo } from 'react';
import { BrowserRouter as Router, StaticRouter } from 'react-router-dom';
import { RouterApp } from '@app/shared/components/RouterApp';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { AppLoaderProps } from './AppLoader.type';
import loadable from '@loadable/component';
import BrandLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const LoadableNotes = loadable(() => import('@app/notes/main/Notes'), {
  resolveComponent: (components) => components.Notes,
  fallback: <BrandLoader position="center" />,
});

const LoadableChangeAddress = loadable(() => import('@app/changeAddress/main/ChangeAddress'), {
  resolveComponent: (components) => components.ChangeAddress,
  fallback: <BrandLoader position="center" />,
});

const LoadableDeliveryPhone = loadable(() => import('@app/deliveryPhone/main/DeliveryPhone'), {
  resolveComponent: (components) => components.DeliveryPhone,
  fallback: <BrandLoader position="center" />,
});

const LoadableDeliveryType = loadable(() => import('@app/deliveryType/main/DeliveryType'), {
  resolveComponent: (components) => components.DeliveryType,
  fallback: <BrandLoader position="center" />,
});

export const AppLoader: FC<AppLoaderProps> = memo(function AppLoader({
  flow,
  mock,
  isServer,
  location,
  loadChangeAddress,
  loadNotes,
  resourceId,
  resourceType,
  changeAddressCallbacks,
  notesCallbacks,
  deliveryPhoneCallbacks,
  header,
  loadDeliveryPhone = false,
  loadDeliveryType = false,
  deliveryTypeCallbacks,
  footerClassName,
  footerNavigationStyle,
}) {
  const { isParcel } = usePublicEnv();
  const context = {};

  if (isParcel !== false) {
    if (loadChangeAddress) {
      return (
        <LoadableChangeAddress
          isParcel={true}
          resourceId={resourceId}
          resourceType={resourceType}
          flow={flow}
          changeAddressCallbacks={changeAddressCallbacks}
          mock={mock}
          header={header}
          footerClassName={footerClassName}
          footerNavigationStyle={footerNavigationStyle}
        />
      );
    }

    if (loadNotes) {
      return (
        <LoadableNotes
          isParcel={true}
          resourceId={resourceId}
          resourceType={resourceType}
          flow={flow}
          notesCallbacks={notesCallbacks}
          mock={mock}
          header={header}
          footerClassName={footerClassName}
          footerNavigationStyle={footerNavigationStyle}
        />
      );
    }

    if (loadDeliveryPhone) {
      return (
        <LoadableDeliveryPhone
          isParcel={true}
          resourceId={resourceId}
          resourceType={resourceType}
          flow={flow}
          mock={mock}
          header={header}
          deliveryPhoneCallbacks={deliveryPhoneCallbacks}
          footerClassName={footerClassName}
          footerNavigationStyle={footerNavigationStyle}
        />
      );
    }

    if (loadDeliveryType) {
      return (
        <LoadableDeliveryType
          isParcel={true}
          resourceId={resourceId}
          resourceType={resourceType}
          flow={flow}
          mock={mock}
          header={header}
          deliveryTypeCallbacks={deliveryTypeCallbacks}
          footerClassName={footerClassName}
          footerNavigationStyle={footerNavigationStyle}
        />
      );
    }
  }

  return <RouterApp />;
});
