import {
  BrowserClient,
  Exception,
  Hub,
  BrowserTracing,
  defaultStackParser,
  makeFetchTransport,
  Scope,
} from '@sentry/react';
import type { Init, User } from './types';
const { PROD_ENVIRONMENT } = require('@appscore/web-constants/environments');

let customHub: Hub;

const sentryHub = {
  init: ({ environment }: Init) => {
    if (customHub) {
      return;
    }
    const isLive = environment === PROD_ENVIRONMENT;

    const client = new BrowserClient({
      dsn: SENTRY_DSN,
      environment,
      integrations: [
        new BrowserTracing({
          _metricOptions: {
            _reportAllChanges: true,
          },
        }),
      ],
      release: SENTRY_RELEASE,
      tracesSampleRate: isLive ? 0.1 : 1,
      sampleRate: isLive ? 0.1 : 1,
      stackParser: defaultStackParser,
      transport: makeFetchTransport,
    });

    customHub = new Hub(client);
    return customHub;
  },
  captureMessage: (message: string) => {
    if (!customHub) return null;
    customHub.run((hub) => hub.captureMessage(message));
  },
  captureException: (ex: Exception) => {
    if (!customHub) return null;
    customHub.run((hub) => hub.captureException(ex));
  },
  setTag: (key: string, value: string) => {
    if (!customHub) {
      throw new Error('Cannot set tags before hub initialization');
    }
    customHub.setTag(key, value);
  },
  setUser: (user: User) => {
    if (!customHub) {
      throw new Error('Cannot set user before hub initialization');
    }
    customHub.setUser(user);
  },
  withScope: (cb: (scope: Scope) => void) => {
    if (!customHub) {
      throw new Error('Cannot set user before hub initialization');
    }
    customHub.run((hub) => {
      hub.withScope(cb);
    });
  },
};

Object.freeze(sentryHub);

export { sentryHub, customHub };
