import React, { FC } from 'react';
import { LinkContext } from './context';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { generateLink } from '@app/shared/utils/deeplink';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { LinkProviderProps } from './provider.type';
import { generatePath } from 'react-router-dom';
/**
 * LinkProvider
 *
 * @description Provider for generate link base on device and deviceOS will generate a link or deeplink
 * @param {Object} Props - React Props
 * @returns React.Node
 */
const LinkProvider: FC<LinkProviderProps> = ({ children }) => {
  const { device, deviceOS } = usePublicEnv();

  const contextValue = {
    /**
     * redirect
     *
     * @description Function for make a redirect based on device
     * @param {ACTIONS} action  - Action to generate link
     * @param {Object} params - Params required to build de url
     */
    redirect: (action: string, params: { [key: string]: any } = {}) => {
      window.location.href = generateLink(device, deviceOS, action, params || {});
    },
    /**
     * redirectUrl
     *
     * @description Function to redirect to a route, given the
     * microsite is rendered in server side
     * @param path - url o redirect
     * @param params - params to key in path
     */
    redirectUrl: (
      path: string,
      params?: { [key: string]: any },
      query?: { [key: string]: any },
    ) => {
      const search = new URLSearchParams(query).toString();
      const result = search ? '?' + search : '';
      const finalPath = generatePath(path, params);
      window.location.href = `${finalPath}${result}`;
    },
  };

  return <LinkContext.Provider value={contextValue}>{children}</LinkContext.Provider>;
};

export { LinkProvider };
