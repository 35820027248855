import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import pkg from '../package.json';
import Root from './root.component';
import { ErrorBoundary } from '@app/shared/components/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';

const rootId = `single-spa-application:${pkg.name}`;
let domElement = document.getElementById(rootId);

if (!document.body.contains(domElement)) {
  domElement = document.createElement('section');
  domElement.id = rootId + Math.random();
  document.body.appendChild(domElement);
}

const lifecycles = singleSpaReact({
  domElementGetter: () => domElement || document.body,
  React,
  ReactDOM,
  renderType: () => (domElement && domElement.hasChildNodes() ? 'hydrate' : 'render'),
  rootComponent: (props) => (
    <BrowserRouter>
      <Root {...props} />
    </BrowserRouter>
  ),
  errorBoundary: (error): any => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
