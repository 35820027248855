import React from 'react';
import PropTypes from 'prop-types';
import fenixTheme from '@pedidosya/web-fenix/theme';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import { createIntlProvider, getMessages } from '@app/shared/contexts/IntlProvider';
import { SentryProvider } from '@app/shared/contexts/SentryProvider';
import { TrackerProvider } from '@app/shared/contexts/TrackerProvider';
import { LocalStyle } from '@app/shared/components/LocalStyle';
import { GlobalError } from '@app/shared/components/GlobalError';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from '@app/shared/contexts/PublicEnv';
import { AppLoader } from '@app/shared/components/AppLoader';
import { LinkProvider } from '@app/shared/contexts/LinkProvider';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const Root = ({
  context,
  messages,
  name,
  location,
  isServer,
  trackerConfig,
  tracker,
  resourceId,
  resourceType,
  flow,
  mock,
  loadChangeAddress,
  loadNotes,
  changeAddressCallbacks,
  notesCallbacks,
  header,
  loadDeliveryPhone,
  deliveryPhoneCallbacks,
  loadDeliveryType,
  deliveryTypeCallbacks,
  footerClassName,
  footerNavigationStyle,
}) => {
  return (
    <GlobalError>
      <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
        <SentryProvider>
          <ThemeProvider theme={fenixTheme}>
            <>
              <FenixFonts />
              <LocalStyle />
              <IntlProvider initialMessages={messages}>
                <TrackerProvider tracker={tracker} trackerConfig={trackerConfig}>
                  <LinkProvider>
                    <AppLoader
                      isServer={isServer}
                      location={location}
                      resourceId={resourceId}
                      resourceType={resourceType}
                      flow={flow}
                      mock={mock}
                      loadChangeAddress={loadChangeAddress}
                      loadNotes={loadNotes}
                      changeAddressCallbacks={changeAddressCallbacks}
                      notesCallbacks={notesCallbacks}
                      header={header}
                      loadDeliveryPhone={loadDeliveryPhone}
                      deliveryPhoneCallbacks={deliveryPhoneCallbacks}
                      loadDeliveryType={loadDeliveryType}
                      deliveryTypeCallbacks={deliveryTypeCallbacks}
                      footerClassName={footerClassName}
                      footerNavigationStyle={footerNavigationStyle}
                    />
                  </LinkProvider>
                </TrackerProvider>
              </IntlProvider>
            </>
          </ThemeProvider>
        </SentryProvider>
      </PublicEnvProvider>
    </GlobalError>
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};
Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string,
  isServer: PropTypes.bool,
  trackerConfig: PropTypes.object,
  resourceType: PropTypes.string,
  resourceId: PropTypes.string,
  flow: PropTypes.string,
  tracker: PropTypes.func,
  loadChangeAddress: PropTypes.bool,
  loadNotes: PropTypes.bool,
  mock: PropTypes.bool,
  header: PropTypes.element,
};

export default Root;
