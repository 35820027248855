import React, { FC } from 'react';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import fenixTheme from '@pedidosya/web-fenix/theme';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import Error from '@pedidosya/web-fenix/pages/Error';
import { sentryHub } from '@app/shared/utils/sentry';
import type { ErrorBoundaryProps } from './ErrorBoundary.type';

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ error, children }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHub.captureException(error);

  const reload = (): void => {
    window.location.reload();
  };

  return (
    <ThemeProvider theme={fenixTheme}>
      <FenixFonts />
      <Error onClick={reload} />
    </ThemeProvider>
  );
};
